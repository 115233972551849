import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginRequest, LoginSessionData } from './model';
import { SessionService } from './session.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

export const InterceptorSkip = 'X-Skip-Interceptor';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  onFormReset = new Subject<void>();

  headers = new HttpHeaders({ 'X-Skip-Interceptor': '' });

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
    private msalService: MsalService,) { }

  login(payload: LoginRequest): Observable<LoginSessionData> {
    const url: string = environment.AUTHENTICATION_API;
    return this.http.post<LoginSessionData>(url, payload, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  logout(): void {
    this.sessionService.clearSession();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  loginSso() {
    if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
        this.msalService.loginRedirect();
    }
}

  loginUsingSso(loginData: any) {
    const url: string = environment.LOGIN_SSO_URL;
    return this.http.post<any>(url, loginData, { headers: this.headers }).pipe(catchError(this.handleError));
  }
  
  private handleError(error: any): Observable<never> {
    const errMsgStatus = error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    const errMsg = error.message ? error.message : errMsgStatus;
    return throwError(() => new Error(errMsg));
  }
}
