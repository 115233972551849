export const environment = {
  production: false,
  BASE_API: "https://dev.api.campus.wginb.com",
  STATIC_API: "https://dev.api.campus.wginb.com/cbdrive/static",
  AUTHENTICATION_API: "https://dev.api.campus.wginb.com/cblogin/login",
  DRIVE_LIST_API: "https://dev.api.campus.wginb.com/cbdrive//drive/list",
  ADD_PANEL_API:"https://dev.api.campus.wginb.com/cbdrive/panel",
  UPDATE_PANEL_API:"https://dev.api.campus.wginb.com/cbdrive/panel/",
  DRIVE_SUMMARY_API: "https://dev.api.campus.wginb.com/cbdrive",
  GET_PANEL_LIST: "https://dev.api.campus.wginb.com/cbdrive/panel/list",
  DELETE_PANEL_API:"https://dev.api.campus.wginb.com/cbdrive/panel/",
  CAMPUS_DRIVE_API: "https://dev.api.campus.wginb.com/cbdrive/drive",
  BULK_UPLOAD_CANDIDATES_API_ENDPOINT_PATH: "/candidate/bulk",
  ALLOWED_FILE_TYPES_FOR_BULK_UPLOAD: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  GET_DRIVE_PANEL_LIST_API_PATH: "/panel/list",
  RESET_PASSWORD: "https://dev.portal.reset.wginb.com",
  LOGIN_SSO_URL: 'https://dev.api.campus.wginb.com/cblogin/ssologin',
  MSAL_CONFIG: {
    clientId:"f529d809-fdd8-4726-9659-2fc6c6f8f51d",
    authority: "https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808",
    redirectUri: "https://dev.buddy4campus.wginb.com",
    postLogoutRedirectUri: 'https://dev.buddy4campus.wginb.com'
  }

}
