import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { filter, Subject, Subscription, takeUntil } from 'rxjs';

import { AuthenticationService } from '../shared/authentication.service';
import { LoginRequest, LoginSessionData } from '../shared/model';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ToastService } from '../shared/toast/toast.service';
import { SessionService } from '../shared/session.service';
import { UserService } from '../shared/user.service';
import { environment } from 'src/environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  form!: UntypedFormGroup;
  loginSub!: Subscription;
  resetPasswordUrl: string = environment.RESET_PASSWORD;
  private readonly _destroying$ = new Subject<null>();
  loginStatus = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private spinnerService: SpinnerService,
    private notificationService: ToastService,
    private sessionService: SessionService,
    private userService: UserService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,) { }
    

  ngOnInit(): void {
    this.initForm();
    // this.spinnerService.hideSpinner();

    if(!sessionStorage.getItem('isAuthLoading')){
      sessionStorage.setItem('isAuthLoading', "false");
    }
    if(sessionStorage.getItem("isAuthLoading") == "false") {
      this.spinnerService.hideSpinner();
    } else {
      this.spinnerService.showSpinner();
    }

    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (!result) {
          this.setSessionStorageAndLoader("false");
        }
      },
      error: () => {
        this.setSessionStorageAndLoader("false");
      }
    });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.router.navigate(['/login']);
      } else {
        this.setLoginDisplay();
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  initForm(): void {
    this.form = this.fb.group({
      username: [
        '',
        []
      ],
      password: [
        '',
        []
      ],
    });
  }

  createLoginPayload(): LoginRequest {
    const loginPayload = {
      'username': this.form.value.username,
      'password': this.form.value.password
    }
    return loginPayload;
  }

  checkForSubmit(event: KeyboardEvent) {

    if (event.key == 'Enter') {
      this.onSubmit()
    }
  }

  onSubmit(): void {
    this.spinnerService.showSpinner();
    const loginPayload: LoginRequest = this.createLoginPayload();
    this.loginSub = this.authenticationService.login(loginPayload).subscribe({
      next: (data: LoginSessionData) => this.handleLoginResponse(data),
      error: () => {
        this.spinnerService.hideSpinner();
        this.notificationService.showMessage('Invalid credentials', 'error');
      }
    });
  }

  handleLoginResponse(data: LoginSessionData): void {
    if (data?.success) {
      this.sessionService.setSessionObject(data);
      this.sessionService.updateAuthStatus(true);
      this.sessionService.isLoggedIn();
      this.userService.setUserRole();
      this.router.navigate(['drives']);
    } else {
      this.notificationService.showMessage('Invalid credentials', 'error');
    }
    this.spinnerService.hideSpinner();
  }

  onSsoLogin() {
    this.setSessionStorageAndLoader("true");
    this.authenticationService.loginSso();
  }

  setLoginDisplay() {
    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);
      this.setMsalToken();
    } else {
      this.router.navigate(['/login']);
    }
  }

  setMsalToken() {
    const tokenRequest = {
        scopes: ["user.read"],
        account: this.msalService.instance.getAllAccounts()[0]
    }
    this.msalService.acquireTokenSilent(tokenRequest).subscribe((tokenResponse) => {
      if (tokenRequest != null) {
          sessionStorage.setItem("msal-access-token", tokenResponse.accessToken);
          sessionStorage.setItem("msal-id-token", tokenResponse.idToken);
          
          this.authenticationService.loginUsingSso({
            accessToken: sessionStorage.getItem('msal-access-token')!,
            idToken: sessionStorage.getItem('msal-id-token')!,
            email: this.msalService.instance.getActiveAccount()?.username!
          }).subscribe({
            next: (result) => {
              this.handleLoginResponse(result)
              this.setSessionStorageAndLoader("false");
            },
            error: () => {
              this.setSessionStorageAndLoader("false");
            }
          });
      }
    })
  }

  private setSessionStorageAndLoader(value: string) {
    if (value=="false") {
      this.spinnerService.hideSpinner();
    } else {
      this.spinnerService.showSpinner();
    }
    sessionStorage.setItem('isAuthLoading', value);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
    this.loginSub?.unsubscribe();
  }
}