<div class="component-header">
    <div class="component-title">
        CAMPUS DRIVES
    </div>
    <div class="component-search-bar" *ngIf="this.userService.IsAdmin()">
        <p-button 
            class="add-drive-btn" 
            label="Add Drive" 
            (onClick)="showAddDriveDialog()">
        </p-button>

        <span class="p-input-icon-right">
            <i class="pi pi-search" style="color: #367B88"></i>
            <input type="text" pInputText/>
        </span>
    </div>
</div>

<div>
    <p-accordion>
        <ng-container *ngFor="let year of years.slice().reverse()">
          <p-accordionTab [header]="year">
            <p-table [columns]="cols" [value]="getDrivesByYear(year)" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '30rem' }">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{ col.header }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns">
                            <ng-container *ngIf="col.field === 'actions' ">
                              <div class="icon-container" *ngIf="this.userService.IsAdmin()">
                                <i class="pi pi-pencil" (click)="showUpdateDriveDialog(rowData)"></i>
                                <i class="pi pi-trash" (click)="showDeleteDriveDialog(rowData)"></i> 
                              </div>
                            </ng-container>
                            <ng-container *ngIf="col.field === 'COLLEGE'">
                               <a [routerLink]="['/drive-details', rowData.DRIVE_ID]">{{ rowData[col.field] }}</a> 
                            </ng-container>
                            <ng-container *ngIf="col.field === 'STATUS'">
                                {{ rowData[col.field] }}
                                <i class="pi pi-pencil" *ngIf="this.userService.IsAdmin()" style = "margin-left: 10px;" (click)="showUpdateDriveStatusDialog(rowData)"></i>
                           </ng-container>
                           
                            <ng-container *ngIf="!(['actions', 'COLLEGE', 'STATUS']).includes(col.field)">
                              {{ rowData[col.field] }}
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]='cols.length'>No records found.</td>
                  </tr>
              </ng-template>
            </p-table>
          </p-accordionTab>
        </ng-container>
    </p-accordion>
</div>

<p-dialog header="Add Drive" [(visible)]="isShowAddDrive" [style]="{width: '35vw'}" [draggable]="false">
    <div class="p-fluid">
        <form [formGroup]="addDriveForm" (ngSubmit)="onAddDriveFormSubmit()">
          <div class="p-field">
            <label for="college" class=>College</label>
            <p-dropdown 
                formControlName="college" 
                [options]="colleges"
                placeholder="Select college">
            </p-dropdown>
          </div>
          <div class="p-field">
            <label for="year">Year</label>
            <p-dropdown 
                formControlName="year" 
                [options]="years"
                placeholder="Select year">
            </p-dropdown>
          </div>
          <div class="p-field">
            <label for="fromDate">Start Date</label>
                <p-calendar formControlName="fromDate" dateFormat="mm/dd/yy"></p-calendar>
          </div>
          <div class="p-field">
            <label for="toDate">End Date</label>
                <p-calendar formControlName="toDate" dateFormat="mm/dd/yy"></p-calendar>
          </div>
          <div class="p-field">
            <label for="comment">Comment</label>
            <textarea 
              rows="5" 
              cols="30" 
              pInputTextarea [autoResize]="true"
              id="comment"
              formControlName="comment"
              style="min-height: 36px; white-space: pre-wrap;"
              [maxlength]="textAreaLimit">
            </textarea>
            <div [ngStyle]="{color: 'red', 'margin-top': '5px'}" *ngIf="addDriveForm.controls['comment'].hasError('maxlength')">
              Maximum character limit reached.
            </div>
          </div>
          <div class="p-field">
            <label for="contact">Contact</label>
            <textarea 
              rows="5" 
              cols="30" 
              pInputTextarea [autoResize]="true"
              id="contact"
              formControlName="contact"
              style="min-height: 36px; white-space: pre-wrap;">
            </textarea>
          </div>
          <button 
            pButton 
            type="submit" 
            label="Submit" 
            [disabled]="!addDriveForm.valid" 
            [style]="{width: '5vw'}">
          </button>
        </form>
      </div>      
</p-dialog>

<p-dialog header="Delete Drive" [(visible)]="isShowDeleteDrive" [style]="{width: '30vw'}" [draggable]="false">
  <div>
    <p style="margin-bottom: 20px;">Are you sure you want to delete this drive ?</p>
    <p-button 
      [ngStyle]="{ 'margin-right': '10px' }"
      type="button" 
      label="Confirm"
      (click)="deleteDrive()">
    </p-button>
    <p-button class="p-cancel-btn"
      type="button" 
      label="Cancel" 
      (click)="hideDeleteDriveDialog()">
    </p-button>
  </div>      
</p-dialog>

<p-dialog header="Update Drive" *ngIf="isShowUpdateDrive" [(visible)]="isShowUpdateDrive" [style]="{width: '35vw'}" [draggable]="false">
  <div class="p-fluid">
      <form [formGroup]="updateDriveForm" (ngSubmit)="onUpdateDriveFormSubmit()">
          <div class="p-field">
            <label for="college">College</label>
            <input id="college" type="text" pInputText formControlName="college" readonly [style]="{background: '#dddddd'}"/>
          </div>
          <div class="p-field">
            <label for="year">Year</label>
            <input id="year" type="text" pInputText formControlName="year" readonly [style]="{background: '#dddddd'}"/>
          </div>
        <div class="p-field">
          <label for="status">Status</label>
          <p-dropdown 
            formControlName="status" 
            [options]="driveStatus"
            placeholder="Select drive status">
          </p-dropdown>
        </div>
        <div class="p-field">
          <label for="fromDate">Start Date</label>
              <p-calendar formControlName="fromDate" dateFormat="mm/dd/yy"></p-calendar>
        </div>
        <div class="p-field">
          <label for="toDate">End Date</label>
              <p-calendar formControlName="toDate" dateFormat="mm/dd/yy"></p-calendar>
        </div>
        <div class="p-field">
          <label for="comment">Comment</label>
          <textarea 
            rows="5" 
            cols="30" 
            pInputTextarea [autoResize]="true"
            id="comment"
            formControlName="comment"
            style="min-height: 36px;"
            [maxlength]="textAreaLimit">
          </textarea>
          <div [ngStyle]="{color: 'red', 'margin-top': '5px'}" *ngIf="updateDriveForm.controls['comment'].hasError('maxlength')">
            Maximum character limit reached.
          </div>
        </div>
        <div class="p-field">
          <label for="contact">Contact</label>
          <textarea 
              rows="5" 
              cols="30" 
              pInputTextarea [autoResize]="true"
              id="contact"
              formControlName="contact"
              style="min-height: 36px;">
          </textarea>
        </div>
        <p-button  
          type="submit" 
          label="Submit" 
          [disabled]="!updateDriveForm.valid" 
          [style]="{width: '5vw'}">
        </p-button>
      </form>
    </div>      
</p-dialog>

<p-dialog header="Update Drive Status" *ngIf="isShowUpdateDriveStatus" [(visible)]="isShowUpdateDriveStatus" [style]="{width: '35vw'}" [draggable]="false">
  <div class="p-fluid">
      <form [formGroup]="updateDriveForm" (ngSubmit)="onUpdateDriveFormSubmit()">
        <div class="p-field">
          <label for="status">Status</label>
          <p-dropdown 
            formControlName="status" 
            [options]="driveStatus"
            placeholder="Select drive status">
          </p-dropdown>
        </div>
        <div class="p-field">
          <label for="comment">Comment</label>
          <input
            id="comment"
            type="text"
            pInputText
            formControlName="comment"/>
        </div>
        <p-button  
          type="submit" 
          label="Submit" 
          [disabled]="!updateDriveForm.valid" 
          [style]="{width: '5vw'}">
        </p-button>
      </form>
    </div>      
</p-dialog>