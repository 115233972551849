import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TimelineModule } from 'primeng/timeline';
import { PanelModule } from 'primeng/panel';
import { ClipboardModule } from 'ngx-clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header/header.component';
import { TokenInterceptor } from './shared/token.interceptor';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { CampusDrivesComponent } from './campus-drives/campus-drives/campus-drives.component';
import { PanelsComponent } from './panels/panels/panels.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { ToastComponent } from './shared/toast/toast.component';
import { DriveSummaryComponent } from './drive-details/drive-summary/drive-summary.component';
import { DriveCandidatesComponent } from './drive-details/drive-candidates/drive-candidates/drive-candidates.component';
import { DriveInterviewsComponent } from './drive-details/drive-interviews/drive-interviews.component';
import { DriveDetailsComponent } from './drive-details/drive-details/drive-details.component';
import { DrivePanelsComponent } from './drive-details/drive-panels/drive-panels.component';
import { DriveCandidatesSelectedComponent } from './drive-details/drive-candidates-selected/drive-candidates-selected.component';
import { DrivePipelineComponent } from './drive-details/drive-pipeline/drive-pipeline.component';
import { LoginComponent } from './login/login.component';
import { ConfirmDialogModule }  from 'primeng/confirmdialog'; 
import { ConfirmationService } from 'primeng/api';
import { environment } from '../environments/environment';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';


const msalConfig = environment.MSAL_CONFIG;
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    CampusDrivesComponent,
    PanelsComponent,
    ReportsComponent,
    SpinnerComponent,
    ToastComponent,
    DriveSummaryComponent,
    DriveCandidatesComponent,
    DriveInterviewsComponent,
    DriveDetailsComponent,
    DrivePanelsComponent,
    DriveCandidatesSelectedComponent,
    DrivePipelineComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MenubarModule,
    MenuModule,
    AccordionModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    ProgressSpinnerModule,
    CalendarModule,
    ToastModule,
    MessagesModule,
    SidebarModule,
    CardModule,
    DividerModule,
    FileUploadModule,
    CheckboxModule,
    InputTextareaModule,
    TimelineModule,
    PanelModule,
    ClipboardModule,
    ConfirmDialogModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: msalConfig.clientId,
          authority: msalConfig.authority,
          redirectUri: msalConfig.redirectUri,
          postLogoutRedirectUri: msalConfig.postLogoutRedirectUri
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: isIE
        }
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read"]
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(
          [
            ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
          ]
        )
      }
    )
  ],
  providers: [
    DatePipe,
    MessageService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

